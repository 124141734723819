.radioCard {
  margin: 1rem !important;
  border: 1px solid #cdcdcd;
  transition: all ease .3s;
  cursor: pointer;

  & > div {
    padding: 1rem;
  }

  &.active {
    border: 2px solid var(--color-brand);
    
    &:hover {
      border: 2px solid var(--color-brand);
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
    }

    &:before {
      width: 20px;
      height: 20px;
      background: #fff;
      border: 2px solid var(--color-brand);
      top: -10px;
      left: -10px;
    }

    &:after {
      width: 10px;
      height: 10px;
      top: -5px;
      left: -5px;
      background: var(--color-brand);
    }
  }

}