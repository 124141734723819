@import '../../assets/sass/variable.scss';

.summary {
  border: 1px solid var(--color-brand) !important;

  & > div {
    padding: 0;

    .item {
      padding: 10px 0;
    }

    h5 {
      color: var(--color-medium-shade);
    }
    
    .quantity {
      font-size: 12px;
      color: var(--color-medium);
    }

    .price {
      text-align: right;
      h4, h5 {
        color: var(--color-brand);
      }
      span {
        font-size: 12px;
        color: var(--color-medium);
      }
    }

    .priceMuted {
      h4, h5 {
        font-size: 13px;
        margin-right: 2rem;
        color: var(--color-medium);
      }
    }
    
    .summaryTitle {
      text-transform: uppercase;
      letter-spacing: 2px;
      font-size: 14px;
      font-weight: bold;
    }
    
    .summaryItem,
    .summaryTotal {
      padding: 0 16px;
    }

    .summaryItem {
      border-bottom: 1px solid var(--color-brand);
      padding-bottom: 8px;
    }

    .summaryTitle,
    .summaryAction {
      padding: 16px;
    }

  }

  &.muted {
    border: 0 !important;
    
    & > div {
      padding: 0;
  
      .summaryItem,
      .summaryTotal {
        padding: 0;
      }
    }
  }

  .list {
    padding: 6px 0;
    list-style: none;

    .listItem {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2px 0;
    }

    .child {
      color: var(--color-medium);
      font-size: 13px;
      margin: 0;
    }

    .groupName{
      display: flex;
      justify-content: space-between;
      font-size: 11px;
      letter-spacing: 1px;
      color: var(--color-medium);
      text-transform: uppercase;
      border-bottom: 1px solid #eaeaea;
      margin-top: 14px;
      margin-bottom: 2px;
    }
    
    .groupName:first-child{
      margin-top: 0;
    }

    .removeDevice {
      position: relative;
      width: 17px;
      height: 17px;
      border: 1px solid #ec1919;
      transition: all .2s;
      border-radius: 2px;
      cursor: pointer;

      &:hover {
        border: 1px solid #ff7c7c;
        border-radius: 2px;

        &:before,
        &:after {
          background-color: #ff7c7c;
        }
      }

      &:before,
      &:after {
        position: absolute;
        content: ' ';
        height: 12px;
        width: 1px;
        background-color: #ec1919;
        right: 7px;
        top: 2px;
      }
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }
    }
  }
}