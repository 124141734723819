@import 'assets/sass/utility.scss';

@font-face {
  src: url('./assets/fonts/Nootype-Radikal.otf') format('opentype');
  font-family: 'Nootype Radikal';
  font-weight: 400;
}
@font-face {
  src: url('./assets/fonts/Nootype-Radikal-Medium.otf') format('opentype');
  font-family: 'Nootype Radikal';
  font-weight: 600;
}
@font-face {
  src: url('./assets/fonts/Nootype-Radikal-Bold.otf') format('opentype');
  font-family: 'Nootype Radikal';
  font-weight: 700;
}

:root {
  --color-brand: #440099;
  --color-brand-bg: #d6dde8;
  --color-light: #f5f5f5;
  --color-light-shade: #cbcbcb;
  --color-medium: #9e9e9e;
  --color-medium-shade: #757575;
  --color-dark: #424242;
  --color-dark-shade: #212121;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Nootype Radikal', sans-serif;
  letter-spacing: .4px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.brand-bg {
  background: var(--color-brand-bg);
}

.text-brand {
  color: var(--color-brand);
}

.error-text {
  font-size: 12px;
  color: #ff4d4f;
}

.reward-text {
  font-size: 12px;
  color: #440099;
}

.img-thumb {
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.prefix-label {
  line-height: 38px;
  border: 1px solid #d9d9d9;
  border-right: 0;
  color: #8a8a8a;
  background: #f1f1f1;
  width: 120px;
  text-align: center;
}

.ant-picker-year-panel,
.ant-picker-month-panel {
  .ant-picker-cell {
    .ant-picker-cell-inner {
      width: 70px;
      height: 42px;
      line-height: 42px;
    }
  }
}

.ant-layout {
  background: #fff;
}

.ant-layout-header {
  background: #440099;
  
  &.shadow {
    box-shadow: 0 2px 8px rgba(0,0,0,0.08);
  }

  &.fixed {
    position: fixed;
    z-index: 1;
    width: 100%;
  }
}

@media (max-width: ($breakpoint-md - 1)) {
  .ant-layout-header {
    padding: 0;
  }

  .ant-steps-horizontal {
    &:not(.ant-steps-label-vertical) {
      & .ant-steps-item {
        padding-left: 0;
      }
    }
  }
}

.ant-layout-content {
  margin-top: 100px;
}

.ant-layout-footer {
  padding: 0;
  margin-top: 4rem;
}

.ant-form-item {
  margin-bottom: 16px;
}

.ant-form-item-explain {
  font-size: 12px;
  letter-spacing: .3px;
}

.ant-steps-item-process > .ant-steps-item-container >
.ant-steps-item-content > .ant-steps-item-title::after,
.ant-steps-item-wait > .ant-steps-item-container >
.ant-steps-item-content > .ant-steps-item-title::after {
  background-color: var(--color-light-shade);
}

.ant-input-group {
  &.ant-input-group-compact {
    &.flex-group {
      display: flex;

      .ant-select {
        flex: auto;
      }
    }
  }
}

.ant-modal-mask {
  backdrop-filter: blur(5px);
}

.ant-divider {
  border-top: 1px solid var(--color-light-shade);
  opacity: .4;
}

.ant-select-lg,
.ant-input-lg,
.ant-picker-large .ant-picker-input > input {
  font-size: 14px;
  line-height: 25px;
}

.ant-picker-content {
  .ant-picker-cell::before {
    display: none;
  }
}

.accordion {
  .ant-collapse-header {
    box-shadow: 0 4px 8px rgba(0,0,0,0.08);
    background: #e3e3eb;
    letter-spacing: 2px;
    font-weight: bold;
    margin: 5px 0;
  }

  .ant-collapse-content-box {
    padding: 0 !important;
  }
}

.radio-pile {
  &.ant-radio-wrapper {
    background: transparent;
    padding: 2px 5px;
    border-radius: 15px;
    border: 1px solid #ddd;
  }
  &.ant-radio-wrapper-checked {
    border: 1px solid #440099;
  }
}

.hide-handler {
  .ant-input-number-handler-wrap {
    display: none;
  }
  &.text-center {
    .ant-input-number-input {
      text-align: center;
    }
  }
}

.image-upload {
  .ant-descriptions-item-content{
    display: block
  }
}


.review {
  font-size: 16px;

  & > div {
    border: 1px solid var(--color-light);
    padding: 2rem 1.5rem !important;
  }

  .referral {
    &::before {
      content: ' ';
      background: var(--color-light);
      position: absolute;
      height: 2px;
      left: -12px;
      right: -12px;
      top: 0;
    }
  }
  
  .header-margin-sm {
    .ant-descriptions-header {
      margin-bottom: $margin-sm;
    }
  }

  .ant-descriptions-title {
    color: rgba(0, 0, 0, 0.65);
    text-transform: uppercase;
  }

  .number {
    flex: 0 0 calc(100% - 1px);
    max-width: calc(100% - 1px);
  }

  .verify {
    background-color: #f1f1f1;
  }

}

.scanner {
  .ant-modal-content {
    background: unset;
  }
  .ant-modal-body {
    padding: 0;
  }
}

.term-condition {
  .ant-collapse {
    border: 0;
    background-color: #fff;
    .ant-collapse-item {
      border-bottom: 0;
    }
    .ant-collapse-header {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
      background: #e3e3eb;
      letter-spacing: 1px;
      font-weight: bold;
      margin: 8px 0;
    }
    .ant-collapse-content {
      border-top: 0;
    }
  }
}

#interactive {
  &.hidden {
    display: none;
  }
  
  &.viewport {
    position: relative;
    & > canvas,
    & > video {
      max-width: 100%;
      width: 100%;
    }

    &.drawing,
    &.drawingBuffer {
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

.ant-btn-primary {
  border-color: #000000;
  background: #440099;
}

.ant-btn-primary:focus, .ant-btn-primary:hover {
  color: #fff;
  border-color: #440099;
  background: #6b429d;
}

.ant-btn-background-ghost.ant-btn-primary {
  color: #440099;
  border-color: #440099;
  text-shadow: none;
}

.ant-btn-background-ghost.ant-btn-primary:hover {
  color: #6b429d;
  border-color: #6b429d;
  text-shadow: none;
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
  background: #84bd00;
}

.ant-steps-item-process .ant-steps-item-icon {
  background-color: #84bd00;
  border-color: #84bd00;
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon .ant-steps-icon {
  color: #000;
}

.radio-pile.ant-radio-wrapper-checked {
  border: 1px solid #440099;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #440099;
}

.ant-radio-inner:after {
  background-color: #440099;
}

.ant-radio-input:focus+.ant-radio-inner, .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner {
  border-color: #440099;
}


.ant-btn-primary[disabled], .ant-btn-primary[disabled]:active, .ant-btn-primary[disabled]:focus, .ant-btn-primary[disabled]:hover {
  color: #ffffff;
  background-color: #b499d6;
  border-color: #ffffff;
}

.ant-input:focus, .ant-input:hover, 
.ant-select:hover, .ant-select-open, .ant-select-focused,
.ant-picker:hover, .ant-picker-focused, 
.ant-btn-circle:hover, .ant-btn-circle:focus, 
.ant-input-number:hover, .ant-input-number-focused,
.ant-input-affix-wrapper:hover, .ant-input-affix-wrapper:focus, 
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.ant-input-affix-wrapper-focused
{
  border:1px solid #440099;
  box-shadow: 0 0 10px #6b429d;
  .anticon-plus, .anticon-minus{
    color: #440099;
  }
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-input-affix-wrapper .ant-input:focus, .ant-input-affix-wrapper .ant-input:hover
{
  border:0px;
  box-shadow: none;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #b499d6;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  color: #fff;
  background: #b499d6;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #84bd00;
  border-color: #84bd00;
}

.ant-steps-icon .ant-steps-finish-icon{
  color: #000000;
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title:after {
  background-color: #84bd00;
}

.ant-switch-checked {
  background-color: #440099;
}

a {
  color: #440099;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #440099;
  border-color: #440099;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover:after {
  border-bottom: 2px solid #440099;
}

.ant-btn:focus, .ant-btn:hover {
  color: #440099;
  border-color: #440099;
  background: #fff;
}

.ant-menu-title-content {
  color: #fff;
}

.ant-menu-title-content:hover, .ant-menu-title-content:focus, a:active, a:focus, a:hover {
  color: #fedd00 !important
}