// Name:            Margin
// Description:     Utilities for margins
//
// Component:       `margin-*`
//                  `margin-sm-*`
//                  `margin-md-*`
//                  `margin-lg-*`
//                  `margin-xl-*`
//                  `margin-rm-*`
//                  `margin-auto-*`

/* ========================================================================
   Component: Margin
 ========================================================================== */

/*
 * Default
 */

.margin {
  margin: $margin;
}
.margin-top {
  margin-top: $margin !important;
}
.margin-bottom {
  margin-bottom: $margin !important;
}
.margin-left {
  margin-left: $margin !important;
}
.margin-right {
  margin-right: $margin !important;
}

/* Small
  ========================================================================== */

.margin-sm {
  margin: $margin-sm;
}
.margin-sm-top {
  margin-top: $margin-sm !important;
}
.margin-sm-bottom {
  margin-bottom: $margin-sm !important;
}
.margin-sm-left {
  margin-left: $margin-sm !important;
}
.margin-sm-right {
  margin-right: $margin-sm !important;
}

/* Medium
  ========================================================================== */

.margin-md {
  margin: $margin-md;
}
.margin-md-top {
  margin-top: $margin-md !important;
}
.margin-md-bottom {
  margin-bottom: $margin-md !important;
}
.margin-md-left {
  margin-left: $margin-md !important;
}
.margin-md-right {
  margin-right: $margin-md !important;
}

/* Large
  ========================================================================== */

.margin-lg {
  margin: $margin-lg;
}
.margin-lg-top {
  margin-top: $margin-lg !important;
}
.margin-lg-bottom {
  margin-bottom: $margin-lg !important;
}
.margin-lg-left {
  margin-left: $margin-lg !important;
}
.margin-lg-right {
  margin-right: $margin-lg !important;
}

/* Desktop and bigger */
@media (min-width: $breakpoint-lg) {
  .margin-lg {
    margin: $margin-lg-l;
  }
  .margin-lg-top {
    margin-top: $margin-lg-l !important;
  }
  .margin-lg-bottom {
    margin-bottom: $margin-lg-l !important;
  }
  .margin-lg-left {
    margin-left: $margin-lg-l !important;
  }
  .margin-lg-right {
    margin-right: $margin-lg-l !important;
  }
}

/* XLarge
  ========================================================================== */

.margin-xl {
  margin: $margin-xl;
}
.margin-xl-top {
  margin-top: $margin-xl !important;
}
.margin-xl-bottom {
  margin-bottom: $margin-xl !important;
}
.margin-xl-left {
  margin-left: $margin-xl !important;
}
.margin-xl-right {
  margin-right: $margin-xl !important;
}

/* Desktop and bigger */
@media (min-width: $breakpoint-lg) {
  .margin-xl {
    margin: $margin-xl-l;
  }
  .margin-xl-top {
    margin-top: $margin-xl-l !important;
  }
  .margin-xl-bottom {
    margin-bottom: $margin-xl-l !important;
  }
  .margin-xl-left {
    margin-left: $margin-xl-l !important;
  }
  .margin-xl-right {
    margin-right: $margin-xl-l !important;
  }
}

/* Auto
  ========================================================================== */

.margin-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.margin-auto-top {
  margin-top: auto !important;
}
.margin-auto-bottom {
  margin-bottom: auto !important;
}
.margin-auto-left {
  margin-left: auto !important;
}
.margin-auto-right {
  margin-right: auto !important;
}

.margin-auto-vertical {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

/* Remove
  ========================================================================== */

.margin-rm {
  margin: 0 !important;
}
.margin-rm-top {
  margin-top: 0 !important;
}
.margin-rm-bottom {
  margin-bottom: 0 !important;
}
.margin-rm-left {
  margin-left: 0 !important;
}
.margin-rm-right {
  margin-right: 0 !important;
}

.margin-rm-vertical {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.margin-rm-adjacent + *,
.margin-rm-first-child > :first-child {
  margin-top: 0 !important;
}
.margin-rm-last-child > :last-child {
  margin-bottom: 0 !important;
}
