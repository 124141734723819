@import '../../assets/sass/variable.scss';

.carouselBlock {
  position: relative;
  padding: 0 2rem;

  .carousel {
    width: 240px;
    padding-bottom: .5rem;
    img {
      width: 100%;
    }
  }

  .carouselPrev,
  .carouselNext {
    position: absolute;
    color: var(--color-brand);
    margin: auto;
    top: 0;
    bottom: 0;

    span {
      font-size: 22px;
    }
    &:focus {
      color: var(--color-brand);
    }
  }
  
  .carouselPrev {
    left: 0;
  }

  .carouselNext {
    right: 0;
  }

  .carouselDots {
    bottom: 0 !important;
    margin-bottom: 0;
    li {
      button {
        background: var(--color-brand) !important;
      }
      &.slick-active {
        button {
          background: var(--color-brand) !important;
        }
      }
    }
  }
}

.price {
  color: var(--color-brand);
  font-weight: 600;
}

.btnVariant {
  margin: 3px;

  &.btnSelected {
    border-color: var(--color-brand);
    color: var(--color-brand);
  }
}

.carouselAction {
  text-align: center;
  .addSummary {
    margin: 2rem 0;
  }
}

.deviceDetail {
  flex: 1;
  padding: 0 1.5rem;

  h4 {
    font-weight: bold;
    text-transform: uppercase;
  }

  ul {
    padding: 0;
    color: var(--color-medium);
    list-style: none;
  }
}