@import '../../assets/sass/variable.scss';

.panel {
  position: relative;
  padding-bottom: 1rem;

  .icon {
    display: flex;
    position: relative;
    margin-right: 1rem;
    margin-bottom: .25rem;
    font-size: 24px;
    color: var(--color-brand);

    h3 {
      margin-left: 1rem;
      color: var(--color-brand);
    }

    svg {
      background: #fff;
      border-radius: 50%;
    }
  }

  &.noLine {
    padding-bottom: 0;
  }

  .content {
    padding-left: 0;
  };
}

@media (min-width: $breakpoint-sm) {
  .panel {
    &:not(.noLine):before {
      content: '';
      background: var(--color-brand);
      position: absolute;
      left: 11px;
      top: 8px;
      height: 100%;
      width: 2px;
    }
    
    .content {
      padding-left: 2.5rem;
    }
  }
}
