@import "./variable.scss";
@import "./margin.scss";
@import "./padding.scss";
@import "./flex.scss";

.container {
  width: 100%;
  margin: auto;
  padding-left: 16px;
  padding-right: 16px;
}

@mixin make-container($breakpoint, $minWidth){
  @media (min-width: $minWidth) {
    .container {
      max-width: $breakpoint;
    }
  }
}

@include make-container($breakpoint-sm - 30, $breakpoint-sm);
@include make-container($breakpoint-md - 48, $breakpoint-md);
@include make-container($breakpoint-lg - 32, $breakpoint-lg);
@include make-container($breakpoint-xl - 60, $breakpoint-xl);
@include make-container($breakpoint-xl + 80, $breakpoint-xxl);

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: $headings-margin-bottom;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
}

h1, .h1 { font-size: $h1-font-size; }
h2, .h2 { font-size: $h2-font-size; }
h3, .h3 { font-size: $h3-font-size; }
h4, .h4 { font-size: $h4-font-size; }
h5, .h5 { font-size: $h5-font-size; }
h6, .h6 { font-size: $h6-font-size; }

.text-center { text-align: center; }
.text-left { text-align: left; }
.text-right { text-align: right; }
.text-bold { font-weight: bold; }
.text-small { font-size: 12px; }

.width-xs { width: $width-xs; }
.width-sm { width: $width-sm; }
.width-md { width: $width-md; }
.width-lg { width: $width-lg; }
.width-xl { width: $width-xl; }
.width-xxl { width: $width-xxl; }
.width-max-sm { max-width: $width-sm; }
.width-max-md { max-width: $width-md; }
.width-max-lg { max-width: $width-lg; }
.width-max-xl { max-width: $width-xl; }
.width-max-xxl { max-width: $width-xxl; }
.width-full { width: 100% }
