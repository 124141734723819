$margin:         20px !default;
$margin-sm:      10px !default;
$margin-md:      40px !default;
$margin-lg:      40px !default;
$margin-lg-l:    70px !default;
$margin-xl:      70px !default;
$margin-xl-l:    140px !default;

$padding:         20px !default;
$padding-sm:      10px !default;
$padding-md:      40px !default;
$padding-lg:      40px !default;
$padding-lg-l:    70px !default;
$padding-xl:      70px !default;
$padding-xl-l:    140px !default;

$breakpoint-sm:   576px !default;
$breakpoint-md:   768px !default;
$breakpoint-lg:   992px !default;
$breakpoint-xl:   1200px !default;
$breakpoint-xxl:  1600px !default;

$headings-margin-bottom:    0.75rem !default;
$headings-font-weight:      500 !default;
$headings-line-height:      1.2 !default;

$h1-font-size:    40px !default;
$h2-font-size:    30px !default;
$h3-font-size:    24px !default;
$h4-font-size:    20px !default;
$h5-font-size:    16px !default;
$h6-font-size:    14px !default;

$width-xs:    110px !default;
$width-sm:    150px !default;
$width-md:    300px !default;
$width-lg:    450px !default;
$width-xl:    600px !default;
$width-xxl:   750px !default;