.menu {
  border-bottom: 0 !important;
  background: unset !important;
  width: 60px;
  display: flex;
  justify-content: flex-end;
}
.menuItem {
  transition: all .1s;
  font-weight: 500;
  color: var(--color-medium-shade) !important;

  a:hover {
    color: inherit !important;
  }

  &:hover {
    color: var(--color-brand) !important;
    border-bottom: 2px solid transparent !important;
  }
}

@media (min-width: 640px) {
  .menu {
    width: 100%;
  }
}