// Name:            Padding
// Description:     Utilities for Paddings
//
// Component:       `padding-*`
//                  `padding-sm-*`
//                  `padding-md-*`
//                  `padding-lg-*`
//                  `padding-xl-*`
//                  `padding-rm-*`

/* ========================================================================
   Component: Padding
 ========================================================================== */

/*
 * Default
 */

.padding {
  padding: $padding;
}
.padding-top {
  padding-top: $padding !important;
}
.padding-bottom {
  padding-bottom: $padding !important;
}
.padding-left {
  padding-left: $padding !important;
}
.padding-right {
  padding-right: $padding !important;
}

/* Small
  ========================================================================== */

.padding-sm {
  padding: $padding-sm;
}
.padding-sm-top {
  padding-top: $padding-sm !important;
}
.padding-sm-bottom {
  padding-bottom: $padding-sm !important;
}
.padding-sm-left {
  padding-left: $padding-sm !important;
}
.padding-sm-right {
  padding-right: $padding-sm !important;
}

/* Medium
  ========================================================================== */

.padding-md {
  padding: $padding-md;
}
.padding-md-top {
  padding-top: $padding-md !important;
}
.padding-md-bottom {
  padding-bottom: $padding-md !important;
}
.padding-md-left {
  padding-left: $padding-md !important;
}
.padding-md-right {
  padding-right: $padding-md !important;
}

/* Large
  ========================================================================== */

.padding-lg {
  padding: $padding-lg;
}
.padding-lg-top {
  padding-top: $padding-lg !important;
}
.padding-lg-bottom {
  padding-bottom: $padding-lg !important;
}
.padding-lg-left {
  padding-left: $padding-lg !important;
}
.padding-lg-right {
  padding-right: $padding-lg !important;
}

/* Desktop and bigger */
@media (min-width: $breakpoint-lg) {
  .padding-lg {
    padding: $padding-lg-l;
  }
  .padding-lg-top {
    padding-top: $padding-lg-l !important;
  }
  .padding-lg-bottom {
    padding-bottom: $padding-lg-l !important;
  }
  .padding-lg-left {
    padding-left: $padding-lg-l !important;
  }
  .padding-lg-right {
    padding-right: $padding-lg-l !important;
  }
}

/* XLarge
  ========================================================================== */

.padding-xl {
  padding: $padding-xl;
}
.padding-xl-top {
  padding-top: $padding-xl !important;
}
.padding-xl-bottom {
  padding-bottom: $padding-xl !important;
}
.padding-xl-left {
  padding-left: $padding-xl !important;
}
.padding-xl-right {
  padding-right: $padding-xl !important;
}

/* Desktop and bigger */
@media (min-width: $breakpoint-lg) {
  .padding-xl {
    padding: $padding-xl-l;
  }
  .padding-xl-top {
    padding-top: $padding-xl-l !important;
  }
  .padding-xl-bottom {
    padding-bottom: $padding-xl-l !important;
  }
  .padding-xl-left {
    padding-left: $padding-xl-l !important;
  }
  .padding-xl-right {
    padding-right: $padding-xl-l !important;
  }
}

/* Remove
  ========================================================================== */

.padding-rm {
  padding: 0 !important;
}
.padding-rm-top {
  padding-top: 0 !important;
}
.padding-rm-bottom {
  padding-bottom: 0 !important;
}
.padding-rm-left {
  padding-left: 0 !important;
}
.padding-rm-right {
  padding-right: 0 !important;
}

.padding-rm-vertical {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.padding-rm-adjacent + *,
.padding-rm-first-child > :first-child {
  padding-top: 0 !important;
}
.padding-rm-last-child > :last-child {
  padding-bottom: 0 !important;
}
