.footer {
  background: radial-gradient(#fff,rgb(214,221,232));
  padding: 2rem 0;
  .snsIcon {
    height: 40px;
    width: auto;
  }
}
.copyright {
  font-size: 14px;
  background: #440099;
  padding: 1rem;
  color: #fff;
}

.link {
  color: white;
}

.backTop {
  height: 40;
  width: 40;
  line-height: 40px;
  border-radius: 4;
  background-color: #1088e9;
  color: #fff;
  text-align: center;
  font-size: 14
}

@media (min-width: 640px) {
  .footer {
    padding: 5rem 0;
  }
}