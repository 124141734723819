@import '../../assets/sass/variable.scss';

.paymentCard {
  box-shadow: 0 0 16px rgba(0,0,0,0.08);
}

.cardImage {
  display: flex;
  justify-content: flex-start;
  padding-top: 1rem;

  img {
    width: 55px;
    margin: 3px;
  }
}

.headCard {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  margin-bottom: 1rem;

  .name {
    font-size: 14px;
    & span:first-child{
      font-size: 18px;
      padding-right: 6px;
    }
  }
  .price {
    font-size: 18px;
    color: #ff3d3d
  }
}

.selectBox {
  height: 33px;
  vertical-align: middle;
  display: inline-block;
  padding: 0 8px;
  width: 100%;
  color: #777;
  background: #fff;
  border-radius: 2px;
  border: 1px solid #d9d9d9;
}

@media (min-width: $breakpoint-sm) {
  .cardImage {
    justify-content: flex-end;
  }
}
