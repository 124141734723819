.serviceTitle {
  color: var(--color-medium-shade);
}
.serviceGroup {
  display: flex;
  flex-wrap: wrap;
  max-width: 720px;
  margin: 0 auto 2rem;
  justify-content: center;
  margin-bottom: 0;
  img{
    width: 200px;
    margin: 20px;
  }
}