.scanArea {
  position: relative;
  width: 345px;
  height: 345px;
  margin: 0 auto;
  border: 2px dashed var(--color-light);
  background: rgba(0, 0, 0, 0.4);
  color: var(--color-light);

  .title {
    position: absolute;
    text-align: center;
    width: 100%;
    top: 6px;
  }

  .action {
    position: absolute;
    text-align: center;
    width: 100%;
    bottom: 10px;

    button {
      color: var(--color-light);
      backdrop-filter: blur(2px);
      background: rgba(255, 255, 255, 0.2);
      margin: 0 2px;

      &[disabled] {
        color: #b3b3b3;
        &:hover, &:focus, &:active {
          background: rgba(255, 255, 255, 0.2);
        }
      }
    }
  }
  
  .iconBg {
    font-size: 32px;
    text-align: center;
    color: var(--color-light-shade);
  }

  .vCenter {
    top: 50%;
    margin: 0;
    width: 100%;
    position: absolute;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  canvas {
    position: absolute;
    left: 0;
  }
}